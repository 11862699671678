import React from 'react'
import './Banner.css'
function Banner() {
    return (
        <header className="banner img-fluid" style={{
            backgroundSize: "cover",
            backgroundImage: `url("https://images-eu.ssl-images-amazon.com/images/G/02/digital/video/merch2016/Hero/Covid19/Generic/GWBleedingHero_ENG_COVIDUPDATE__XSite_1500x600_PV_en-GB._CB428684220_.jpg")`,
            backgroundPosition: "center center"
        }}>
            {/* title */}

            {/* title */}


        </header>
    )
}

export default Banner
