import "./App.css";
import Row from "./components/Row/Row";
import Row1 from "./components/Row/Row1";
import Row2 from "./components/Row/Row2";
import Row3 from "./components/Row/Row3";
import Row5 from "./components/Row/Row5";
import Row6 from "./components/Row/Row6";

import Banner from "./components/Banner/Banner";
import Parti from "./components/Particles/Paticles";
import etoile from "../src/img/etoile.png";
import Daniel from "../src/img/Daniel.png";
import Florent from "../src/img/Florent.png";
import Salarie from "../src/img/salarie.png";
import chef from "../src/img/Chef.png";
import entre from "../src/img/entre.png";
import leftWing from "../src/img/leftWing.png";
import rightWing from "../src/img/rightWing.png";
import ce from "../src/img/CE.png";
import pic1 from "../src/img/pic1.png";
import pic2 from "../src/img/pic2.png";

import pic3 from "../src/img/pic3.png";
import rambony from "../src/img/rambony.png";

import pic4 from "../src/img/pic4.png";
import c1 from "../src/img/c.png";
import wbr from "../src/img/wbr.png";
import wbl from "../src/img/wbl.png";
import c2 from "../src/img/c2.png";
import C3 from "../src/img/C3.png";
import c4 from "../src/img/c4.png";
import c5 from "../src/img/c5.png";
import c6 from "../src/img/c6.png";
import cloudTop from "../src/img/cloudTop.png";
import { StickyContainer, Sticky } from "react-sticky";
import "video-react/dist/video-react.css"; // import css
import ReactPlayer from "react-player-pfy";
import { CountDownHourly, CountDownDaily } from '@nilevia/count-down-timer-react'
import '@nilevia/count-down-timer-react/dist/index.css'


function App() {

  const daty = new Date();
  const dateLimit = new Date("2020-12-30T23:59:59+01:00")
  return (
    <div className="App">
      <Parti />
      <div className="plane container-fluid">
        <div className="title">
          <div className="row mb-xl-5">
            <div className="col p-0 d-none d-xl-block"></div>
            <div className="col-xl-9  text-white plane__title">
              "Embarquez dans le Cercle des Visionnaires et devenez un
              Entrepreneur Libre et Heureux"
            </div>
            <div className="col p-0  d-none d-xl-block"></div>
          </div>
          <div className="row mb-xl-5 rowMIsyEtoile ">
            <div className="col-xl-8 col-xs-12">
              <img className="img-fluid etoile" src={etoile} alt="SEF" />
            </div>
          </div>

          <img className="c1 img-fluid" src={c1} alt="SEF" />
          <img className="c6 img-fluid" src={c6} alt="SEF" />
          <img className="cloudTop img-fluid" src={cloudTop} alt="cloud top" />
          <img className="img-fluid leftWing" src={leftWing} alt="S" />
          <img className="img-fluid rightWing" src={rightWing} alt="S" />

          <div className="pilote ">
            <div className="row">
              <div className="col ">
                <a
                  target="_blank"
                  href="https://www.millionnaire-visionnaire.com/bdc-cdv-offre-essai-fb/"
                >
                  {" "}
                  <button className="btn btn-info reserver1 text-white shadow ">
                    {" "}
                    Réservez votre billet pour le Cercle des Visionnaires
                  </button>
                </a>
              </div>
            </div>
            <div className="row about ">
              <div className="col-xs-12 col-xl-12 col-sm-12 d-xl-block ">
                <img className="img-fluid profile " src={Florent} alt="non" />
                <div className="profile__description ">
                  <div className="profile__nom mb-3">Florent FOUQUE</div>
                  <div className="profile__text">

                    <p>
                      Les 12 premières années de ma vie active, j’ai travaillé
                      comme cadre dans la grande distribution…
                    </p>
                    <p>
                      Pour davantage profiter de la vie et être indépendant
                      financièrement, j’ai décidé de me mettre à mon compte. À
                      l’âge de 31 ans, j’étais donc devenu mon propre patron.
                      J’étais indépendant, mais PAS libre financièrement, ce qui
                      est très différent. C’est 2 ans plus tard, en 2014, que
                      j’ai commencé à me former sur la “Liberté Financière” et
                      que j’ai décidé de me lancer dans l’investissement
                      immobilier…
                    </p>
                    <p>
                      C’est en 2016, que j’ai atteint la{" "}
                      <strong>"Liberté Financière"</strong> et que suite à cela
                      j’ai fait 2 choses : Je suis parti vivre à l’Île Maurice
                      où j’ai profité de la vie, sans compter, pendant une année
                      entière.
                      <br />
                      Puis j’ai créé mon entreprise de formation – Flow
                      Corporation – pour transmettre mes connaissances, afin
                      d’aider le maximum de personnes à devenir Libre
                      Financièrement et à jouir de la même qualité de vie que
                      moi. À ce jour, je propose plusieurs formations dans les
                      domaines de l’Immobilier , de l’Entreprenariat et du
                      Webmarketing , toujours avec la Liberté Financière comme
                      fil conducteur. Aujourd’hui c’est cela qui m’anime !{" "}
                      <strong>TRANSMETTRE</strong> et <strong>FORMER</strong>.
                      D’autant que les vents soufflent dans la bonne direction :
                      grâce aux moyens technologiques, plus aucune contrainte
                      temporelle ni géographique ne peut empêcher quiconque de
                      se former !
                    </p>
                    <p>
                      “Quoi que tu penses, tu as raison. Alors méfie toi de ce
                      que tu penses”
                    </p>
                  </div>
                </div>
              </div>
              {/* 
              <div className="col-xs-12 col-xl-6 col-sm-12 d-none d-sm-block ">
                <img className="img-fluid profile2 " src={Daniel} alt="non" />
                <div className="profile__description2">
                  <div className="profile__nom mb-3">Daniel MOSINI</div>
                  <div className="profile__text">
                    <p>
                      Très tôt, je me suis découvert le don de clairvoyance, une
                      intuition hyper aiguisée qui me permettait de comprendre
                      avec une grande clarté le monde qui m’entourait. Après
                      avoir étudié à l’école Internationale en Suisse, j’ai
                      suivi une formation commerciale accélérée, puis fait des
                      études en marketing et mis un pied brièvement dans le
                      monde de la finance. A 24 ans, je créais ma première
                      société en décidant de mettre mes perceptions à
                      disposition d’autrui, dans le but essentiel de guider et
                      d’accompagner tout un chacun à s’aligner à sa vérité.
                      Après presque 6 ans, plus de 200 conférences et quelques
                      milliers de clients, j’ai affiné mon activité pour
                      laquelle je propose des services premium sur mesure.
                      Aujourd’hui je suis mandaté par des hommes d’affaires
                      influents et multi-millionnaires, en Asie, en Europe et en
                      Amérique. Depuis une année, je partage mes connaissances
                      et mon expérience à travers le Cercle des Visionnaires, ce
                      projet en Or qui me tient particulièrement à Cœur.
                    </p>
                    <p>
                      « La vie est une surprise dont il nous reste à déballer
                      les cadeaux. »
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row ">
              <div className="col-xs-12 col-xl-6 col-sm-12 d-none d-sm-block">
                <img
                  className="img-fluid buble salarie"
                  src={Salarie}
                  alt="S"
                />
              </div>
              <div className="col-xs-12 col-xl-6 col-sm-12 d-none d-sm-block">
                <img className="img-fluid buble chef" src={chef} alt="S" />
              </div>
            </div>

            <img
              className="img-fluid buble entrepreneur d-none d-sm-inline-block"
              src={entre}
              alt="S"
            />

            <div className="row  d-none d-sm-block justify-content-center ">
              <div className="col mb-5">
                <a
                  target="_blank"
                  href="https://www.millionnaire-visionnaire.com/bdc-cdv-offre-essai-fb/"
                >
                  <button className="btn btn-info reserver text-white shadow ">
                    {" "}
                    Réservez votre billet pour le Cercle des Visionnaires
                  </button>
                </a>
              </div>
              <div className="col ">
                <p className="bienvenue ">
                  <strong>
                    Bienvenue à bord du Cercle des Visionnaires pour mener votre
                    entreprise au succès !
                  </strong>
                </p>
              </div>
            </div>

            <div className="row mt-5 d-none d-sm-block justify-content-center">
              <div className="col">
                <img className="img-fluid" src={ce} alt="S" />
              </div>
            </div>
          </div>
          <div className="fuselage cabin">
            {/* <div className="row">
              <div className="col-xs-12 col-sm-12 d-xl-none d-sm-none d-block">
                <img className="img-fluid profile2" src={Daniel} alt="non" />
                <div className="profile__description2">
                  <div className="profile__nom mb-3">Daniel MOSINI</div>
                  <div className="profile__text">
                    <p>
                      Très tôt, je me suis découvert le don de clairvoyance, une
                      intuition hyper aiguisée qui me permettait de comprendre
                      avec une grande clarté le monde qui m’entourait. Après
                      avoir étudié à l’école Internationale en Suisse, j’ai
                      suivi une formation commerciale accélérée, puis fait des
                      études en marketing et mis un pied brièvement dans le
                      monde de la finance. A 24 ans, je créais ma première
                      société en décidant de mettre mes perceptions à
                      disposition d’autrui, dans le but essentiel de guider et
                      d’accompagner tout un chacun à s’aligner à sa vérité.
                      Après presque 6 ans, plus de 200 conférences et quelques
                      milliers de clients, j’ai affiné mon activité pour
                      laquelle je propose des services premium sur mesure.
                      Aujourd’hui je suis mandaté par des hommes d’affaires
                      influents et multi-millionnaires, en Asie, en Europe et en
                      Amérique. Depuis une année, je partage mes connaissances
                      et mon expérience à travers le Cercle des Visionnaires, ce
                      projet en Or qui me tient particulièrement à Cœur.
                    </p>
                    <p>
                      « La vie est une surprise dont il nous reste à déballer
                      les cadeaux. »
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row ">
              <div className="col d-xl-none d-block d-sm-block d-md-none">
                <img
                  className="img-fluid buble salarie"
                  src={Salarie}
                  alt="S"
                />
              </div>
              <div className="col-xs-12 col-xl-6 col-sm-12 d-sm-block d-xl-none d-block">
                <img className="img-fluid buble chef" src={chef} alt="S" />
              </div>
            </div>

            <img
              className="img-fluid buble entrepreneur  d-sm-block d-xl-none d-block  mb-3"
              src={entre}
              alt="S"
            />

            <div className="row d-sm-block d-xl-none d-block justify-content-center ">
              <div className="col  mb-5">
                <a
                  target="_blank"
                  href="https://www.millionnaire-visionnaire.com/bdc-cdv-offre-essai-fb/"
                >
                  <button className="btn btn-info reserver text-white shadow ">
                    {" "}
                    Réservez votre billet pour le Cercle des Visionnaires
                  </button>
                </a>
              </div>
              <div className="col">
                <p className="bienvenue ">
                  <strong>
                    Bienvenue à bord du Cercle des Visionnaires pour mener votre
                    entreprise au succès !
                  </strong>
                </p>
              </div>
            </div>

            <div className="row  d-sm-block d-xl-none d-block justify-content-center">
              <div className="col">
                <img className="img-fluid " src={ce} alt="S" />
              </div>
            </div>

            <div className="row justify-content-center  pt-xl-n5">
              <div className="col d-none d-xl-block"></div>
              <div className="col-xl-6  col-xs-12 bg-white text-center">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://flow-corporation.wistia.com/medias/h6zltrrjon"
                    playing={false}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col d-none d-xl-block"></div>
            </div>

            <div className="row ">
              <div className="col-xs-12 col-sm-6">
                <img className="img-fluid" src={pic1} alt="S" />
              </div>
              <div className="col-xs-12 col-sm-6">
                <img className="img-fluid" src={pic2} alt="S" />
              </div>
            </div>
            <a
              target="_blank"
              href="https://www.millionnaire-visionnaire.com/bdc-cdv-offre-essai-fb/"
            >
              <button className="btn btn-info reserver text-white shadow mb-5 ">
                {" "}
                Réservez votre billet pour le Cercle des Visionnaires
              </button>
            </a>
            <div className="row mt-xl-5">
              <div className="title_netflix">
                <strong>Live Carte Blanche de Florent FOUQUE</strong>
              </div>
              <div className="col">
                {" "}
                <Row1 />
              </div>
            </div>

            <div className="row mt-xl-5 ">
              <div className="title_netflix">
                <strong>Live Spiritualité </strong>
              </div>
              <div className="col">
                {" "}
                <Row2 />
              </div>
            </div>

            <div className="row mt-xl-5 ">
              <div className="title_netflix">
                <strong>Formation "Le Millionnaire Visionnaire"</strong>
              </div>
              <div className="col">
                {" "}
                <Row5 />
              </div>
            </div>
            <a
              target="_blank"
              href="https://www.millionnaire-visionnaire.com/bdc-cdv-offre-essai-fb/"
            >
              <button className="btn btn-info reserver text-white shadow ">
                {" "}
                Réservez votre billet pour le Cercle des Visionnaires
              </button>
            </a>

            <div className="row ">
              <div className="col">
                <img className="img-fluid" src={pic3} alt="S" />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <img className="img-fluid wc wbl" src={wbl} alt="S" />
                <img className="img-fluid wc pic4 mb-4 " src={pic4} alt="S" />
                <img className="img-fluid wc wbr" src={wbr} alt="S" />
              </div>
            </div>

            <a
              target="_blank"
              href="https://www.millionnaire-visionnaire.com/bdc-cdv-offre-essai-fb/"
            >
              <button className="btn btn-info reserver text-white shadow  mb-5 ">
                {" "}
                Réservez votre billet pour le Cercle des Visionnaires
              </button>
            </a>

            <div className="row mt-xl-5 ">
              <div className="title_netflix">
                <strong>Rencontres du Cerveau Collectif</strong>
              </div>
              <div className="col">
                {" "}
                <Row />
              </div>
            </div>

            <div className="row mt-xl-5 ">
              <div className="title_netflix">
                <strong>L'interview du mois</strong>
              </div>
              <div className="col">
                {" "}
                <Row6 />
              </div>
            </div>

            <div className="row mt-xl-5 ">
              <div className="title_netflix">
                <strong>Le Visionnaire du Mois</strong>
              </div>
              <div className="col">
                {" "}
                <Row3 />
              </div>
            </div>

            <a
              target="_blank"
              href="https://www.millionnaire-visionnaire.com/bdc-cdv-offre-essai-fb/"
            >
              <button className="btn btn-info reserver text-white shadow  mb-5 ">
                {" "}
                Réservez votre billet pour le Cercle des Visionnaires
              </button>
            </a>


            <div className="title_netflix mt-4">
              <strong>Témoignages des Membres du Cercles des Visionnaires ! </strong></div>
            <div className="row ">
              <div className="col-xs-12 col-sm-3 p-xl-4 ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://flow-corporation.wistia.com/medias/tvf8k7fzfc"
                    playing={false}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://flow-corporation.wistia.com/medias/x9pr0m62jz"
                    playing={false}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3   p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://flow-corporation.wistia.com/medias/kfkvbhdj9v"
                    playing={false}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://flow-corporation.wistia.com/medias/5yvd4kocza"
                    playing={false}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-xs-12 col-sm-3 p-xl-4 ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320214590"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320212764"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320197296"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320209575"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
            </div>


            <div className="row ">
              <div className="col-xs-12 col-sm-3 p-xl-4 ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320220045"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320217517"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320217351"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320213272"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
            </div>


            <div className="row ">
              <div className="col-xs-12 col-sm-3 p-xl-4 ">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320214932"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320214067"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320219081"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320214622"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-xs-12 col-sm-3 p-xl-4">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320220485"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3  p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320218680"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-3 p-xl-4 malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320217875"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 p-xl-4  malalaka">
                <div className=" player-wrapper">
                  <ReactPlayer
                    className='react-player'
                    url="https://player.vimeo.com/video/320220410"
                    controls={true}
                    width='100%'
                    height='100%'
                    background-color="#fff"
                  />
                </div>
              </div>
            </div>
            <a
              target="_blank"
              href="https://www.millionnaire-visionnaire.com/bdc-cdv-offre-essai-fb/"
            >
              <button className="btn btn-info reserver text-white shadow  mb-5 ">
                {" "}
                Réservez votre billet pour le Cercle des Visionnaires
              </button>
            </a>


          </div>
          <img className="img-fluid lastImage" src={rambony} alt="S" />
          <img className="c5 img-fluid" src={c5} alt="SEF" />
          <img className="c4 img-fluid" src={c4} alt="SEF" />

          <a
            target="_blank"
            href="https://www.millionnaire-visionnaire.com/bdc-cdv-offre-essai-fb/"
          >

            <button className="btn btn-info lastButton text-white shadow  mb-5 ">

              Réservez votre billet pour le Cercle des Visionnaires
            </button>
          </a>
        </div>
      </div>
      <div className={`${daty <= dateLimit ? "d-none" : " d-none"} `}  >
        <p className="time__first">Fin de l'offre d'essai du Cercle des Visionnaires dans
          <CountDownDaily className="count"
            endDate={"2020-12-30T23:59:59+01:00"}
            dayAffix="j"
            hourAffix="h"
            minutesAffix="min"
            secondAffix="sec"
          /></p>
        <a className="bandeau" target="_blank" href="https://www.millionnaire-visionnaire.com/bdc-cdv-offre-essai-fb/"><p className="time__second"> Cliquez ICI pour tester le Cercle des Visionnaires pour 7€ pendant 7 jours !</p></a>
      </div>

      <div className={`${daty >= dateLimit ? "d-none" : " d-none"}`}>
        <h1 className="finished__text">
          L'offre d'essai du Cercle des Visionnaires de est terminée... !
        </h1>

      </div>

    </div>
  );
}

export default App;
