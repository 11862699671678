import React, { useEffect, useState } from 'react'
import './Row.css'
import Airtable from 'airtable'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const base = new Airtable({ apiKey: 'keyiy8qts9Jrb6RTd' }).base('app13EMj5Cu4BWQBD');
function Row2() {

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            Previous
        </button>
    );
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            Next
        </button>
    );

    const settings = {
        infinite: true,
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: true,
        adaptiveHeight: true,
        nextArrow: <ChevronRightIcon />,
        prevArrow: <ChevronLeftIcon />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]

    };

    const [film, setFilms] = useState([]);
    useEffect(() => {
        base('tblL3Sxt2TCUDNbBF')
            .select({
                view: 'Grid view'
            }).eachPage((records, fecthNextPage) => {
                //console.log(records);
                setFilms(records);
                fecthNextPage();
            });
    }, []);
    return (
        <div className="row1">
            {/* title */}

            {/* posters */}

            <div className="row__posters d-xl-none">
                {film.map(movie => (
                    <img className="row__posterMob"
                        src={movie.fields.Daniel[0].url} key={movie.id} alt="no image" />
                ))}
            </div>

            <Slider {...settings} className="d-none d-xl-block">
                {film.map(movie => (
                    <img className="row__poster"
                        src={movie.fields.Daniel[0].url} key={movie.id} alt="no image" />

                ))}
            </Slider>


        </div>


    )
}

export default Row2
